<template>
  <div>
    <Report module="Boleto Bancário" name="Boleto Bancário" urlGet="/api/v1/billing/report/billet" :header="header"
      :filters="filters" />
  </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
  name: "ReportBillet",
  components: {
    Report
  },
  data() {
    return {
      header: [
        {
          title: "Dt Emissão",
          field: "registeredDateStr",
          fieldOrderBy: "RegisteredDate",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
          initialOrderBy: true,
        },
        {
          title: "Destinatário",
          field: "recipientName",
          fieldOrderBy: "RecipientName",
          show: true,
          styleHeader: "width:550px;",
          styleBody: "max-width: 540px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Doc",
          field: "document",
          fieldOrderBy: "Document",
          show: true,
          styleHeader: "width:50px;",
          styleBody: "max-width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Status",
          field: "statusName",
          fieldOrderBy: "StatusName",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 80px;",
        },
        {
          title: "Dt Vencimento",
          field: "dueDateStr",
          fieldOrderBy: "DueDate",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
          initialOrderBy: true,
        },
        {
          title: "R$ Valor",
          field: "valueStr",
          fieldOrderBy: "Value",
          show: true,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
      ],
      filters: [
        {
          type: "date",
          title: "Dt Emissão",
          field: "RegisteredDate",
          value: "",
          required: true,
        },
        {
          type: "date",
          title: "Dt Vencimento",
          field: "DueDate",
          value: "",
          required: true,
        },
        {
          type: "multiOptions",
          title: "Destinatário",
          field: "RecipientName",
          url: "/api/v1/finance/payable-receivable/select-all-recipient",
          propsParams: {},
          value: "",
        },
        {
          type: "text",
          title: "Documento",
          field: "Document",
          value: "",
        },
        {
          type: "options",
          title: "Status",
          field: "StatusName",
          options: [
            { text: "Alteração Solicitada", value: "Alteração Solicitada" },
            { text: "Alterado", value: "Alterado" },
            { text: "Baixa", value: "Baixa" },
            { text: "Baixa Solicitada", value: "Baixa Solicitada" },
            { text: "Cancelado", value: "Cancelado" },
            { text: "Emitido", value: "Emitido" },
            { text: "Liquidado", value: "Liquidado" },
            { text: "Protestado", value: "Protestado" },
            { text: "Rejeitado", value: "Rejeitado" },
            { text: "Registrado", value: "Registrado" },
          ],
          value: [],
        },
      ],
    }
  }
}


</script>